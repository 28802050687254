import React from "react";

import SecondaryLanding from "../layouts/secondary-landing";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import SEO from "../components/seo/seo";
import BestBanksDefault from "../components/best-banks/best-banks-default";
import Icon from "../components/custom-widgets/icon";

const SupportedBrowsers = () => {
  const title = "Supported Browsers";
  const description = "Using an up-to-date browser improves your security, speed, and experience.";

  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Supported Browsers"
    }
  ];

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/supported-browsers"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const browsersData = [
    {
      title: "Google Chrome",
      browserName: "Chrome",
      desktopSupportedVersions: "90+",
      mobileSupportedVersions: "90+",
      browserUrl: "https://www.google.com/chrome/",
      iconSrc: "/images/icons/chrome-logo.svg"
    },
    {
      title: "Mozilla Firefox",
      browserName: "Firefox",
      desktopSupportedVersions: "92+",
      mobileSupportedVersions: "93+",
      browserUrl: "https://www.mozilla.org/en-US/firefox/new/?redirect_source=firefox-com",
      iconSrc: "/images/icons/firefox-logo.svg"
    },
    {
      title: "Apple Safari",
      browserName: "Safari",
      desktopSupportedVersions: "14+",
      mobileSupportedVersions: "14+",
      browserUrl: "https://support.apple.com/en-us/102665",
      iconSrc: "/images/icons/safari-logo.svg"
    },
    {
      title: "Microsoft Edge",
      browserName: "Microsoft Edge",
      desktopSupportedVersions: "94+",
      mobileSupportedVersions: "94+",
      browserUrl: "https://www.microsoft.com/en-us/edge?ep=0&form=MA13M0&es=40",
      iconSrc: "/images/icons/microsoft-edge-logo.svg"
    }
  ];

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>WaFd Bank Supported Browsers</h1>
        <h3>
          Using an up-to-date browser improves your security, speed, and experience. If you are using a browser that
          WaFd Bank no longer supports, please use the links below to learn more and upgrade to one of these free and
          excellent browsers.
        </h3>
        <div className={`row no-gutters row-cols-1 row-cols-sm-2 row-cols-lg-4`}>
          {browsersData.map((browser, index) => (
            <div key={index} className="mb-3 mb-lg-0">
              <img src={browser.iconSrc} alt="" className="mb-3" width={"120px"} />
              <h3>{browser.title}</h3>
              <p>Desktop Supported Versions: {browser.desktopSupportedVersions}</p>
              <p>Mobile Supported Versions: {browser.mobileSupportedVersions}</p>
              <a
                href={browser.browserUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="font-weight-bold text-decoration-none"
              >
                Update {browser.browserName}
                <Icon name="external-link-alt" lib="far" class="ml-2" />
              </a>
            </div>
          ))}
        </div>
      </section>

      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default SupportedBrowsers;
